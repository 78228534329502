import Keycloak from "keycloak-js";

class SecurityUtil {
  static WERBEPLANER = "ppi-media-werbeplanungstool:Werbeplaner";
  static MARKTBETREIBER = "ppi-media-werbeplanungstool:Marktbetreiber";

  static ROLES = [SecurityUtil.WERBEPLANER, SecurityUtil.MARKTBETREIBER];

  static getRoles(keycloak: Keycloak): string[] {
    return this.isMarktbetreiber(keycloak)
      ? [SecurityUtil.MARKTBETREIBER]
      : [SecurityUtil.WERBEPLANER];
    // : this.isWerbeplaner(keycloak)
    //   ? [SecurityUtil.WERBEPLANER]
    //   : [];
  }

  static isWerbeplaner(keycloak: Keycloak): boolean {
    // TODO - remove !hasRole() expression
    // console.info(
    //   "#### isWerbeplaner",
    //   this.hasRole(keycloak, SecurityUtil.WERBEPLANER) ||
    //     !this.hasRole(keycloak, SecurityUtil.MARKTBETREIBER),
    // );
    return (
      this.hasRole(keycloak, SecurityUtil.WERBEPLANER) ||
      !this.hasRole(keycloak, SecurityUtil.MARKTBETREIBER)
    );
  }

  static isMarktbetreiber(keycloak: Keycloak): boolean {
    // console.info(
    //   "#### isMarktbetreiber",
    //   this.hasRole(keycloak, SecurityUtil.MARKTBETREIBER),
    // );
    return this.hasRole(keycloak, SecurityUtil.MARKTBETREIBER);
  }

  private static hasRole(keycloak: Keycloak, expectedRole: string): boolean {
    // console.info("##", keycloak.tokenParsed);
    let role = keycloak.tokenParsed?.ActiveOrganization?.role;

    if (!role) {
      role = keycloak.tokenParsed?.activeOrganization?.role;
    }

    return role && role.includes(expectedRole);
  }
}

export default SecurityUtil;
