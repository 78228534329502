import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "./domain/layout/Layout";
import AdPlanLayout from "./domain/layout/AdPlanLayout";
import DetailedPlanningLayout from "./domain/layout/DetailedPlanningLayout";

import routes from "./router/routes";
import marketingPartnersRoutes from "./router/marketing-partners-routes";
import detailedPlanningRoutes from "./router/detailed-planning-routes";
import detailedPlannigForClientsRoutes from "./router/detailed-planning-for-clients-routes";
import NotFound from "./components/NotFound";
import detailedPlanningDetailsRoutes from "./router/detailed-planning-details-routes";
import DetailedPlanningDetailsLayout from "./domain/layout/DetailedPlanningDetailsLayout";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useParams } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "./helper/securityUtil";

const App = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();

  const routeComponents = routes.map(
    ({ path, component: Component }, index) => <Component key={path + index} />,
  );

  const detailedPlanningDetailsComponents = detailedPlanningDetailsRoutes.map(
    ({ path, component: Component }, index) => (
      <Routes key={"routes" + path + index}>
        <Route
          path={path}
          element={<Component key={path + index} />}
          key={"route" + path + index}
        />
      </Routes>
    ),
  );

  const { marketingPartnersId, importversion, clientele, year, weekofyear } =
    useParams();

  if (!initialized) {
    return (
      <CircularProgress
        color="primary"
        size={40}
        style={{ margin: "auto", display: "block", marginTop: "50vh" }}
      />
    );
  }

  if (initialized && !keycloak.authenticated) {
    keycloak.login();
    return <></>;
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Navigate
          to={
            SecurityUtil.isMarktbetreiber(keycloak)
              ? "/" +
                (
                  t("detailed-planning-for-clients" as any) as string
                ).toLowerCase()
              : "/" + (t("advertisingschedule" as any) as string).toLowerCase()
          }
        />
      ),
    },
    ...(SecurityUtil.isWerbeplaner(keycloak)
      ? [
          {
            element: <Layout>{routeComponents}</Layout>,
            path:
              "/" + (t("advertisingschedule" as any) as string).toLowerCase(),
          },
          // {
          //     element: <Layout>{routeComponents}</Layout>,
          //     path: "/profile"
          // },
          {
            element: (
              <Layout>
                <AdPlanLayout
                  selectedPartnerId={
                    marketingPartnersId
                      ? parseInt(marketingPartnersId)
                      : undefined
                  }
                ></AdPlanLayout>
              </Layout>
            ),
            path: `/${(
              t("advertisingschedule" as any) as string
            ).toLowerCase()}/:scheduleId`,
            children: marketingPartnersRoutes,
          },
          {
            element: (
              <Layout>
                <DetailedPlanningLayout></DetailedPlanningLayout>
              </Layout>
            ),
            path: `/${(t("detailed-planning" as any) as string).toLowerCase()}`,
            children: detailedPlanningRoutes,
          },
          {
            element: (
              <Layout>
                <DndProvider backend={HTML5Backend}>
                  <DetailedPlanningDetailsLayout>
                    {detailedPlanningDetailsComponents}
                  </DetailedPlanningDetailsLayout>
                </DndProvider>
              </Layout>
            ),
            path:
              "/" +
              (t("detailed-planning" as any) as string).toLowerCase() +
              "/details",
          },
        ]
      : []),
    ...(SecurityUtil.isMarktbetreiber(keycloak)
      ? [
          {
            element: (
              <Layout>
                <DetailedPlanningLayout></DetailedPlanningLayout>
              </Layout>
            ),
            path: `/${(t("detailed-planning-for-clients" as any) as string).toLowerCase()}`,
            children: detailedPlannigForClientsRoutes,
          },
          {
            element: (
              <Layout>
                <DndProvider backend={HTML5Backend}>
                  <DetailedPlanningDetailsLayout>
                    {detailedPlanningDetailsComponents}
                  </DetailedPlanningDetailsLayout>
                </DndProvider>
              </Layout>
            ),
            path:
              "/" +
              (
                t("detailed-planning-for-clients" as any) as string
              ).toLowerCase() +
              "/details",
          },
          {
            element: (
              <Layout>
                <DndProvider backend={HTML5Backend}>
                  <DetailedPlanningDetailsLayout
                    params={
                      weekofyear && clientele && importversion && year
                        ? {
                            clientele: clientele,
                            importVersion: importversion,
                            year: year,
                            weekOfYear: weekofyear,
                          }
                        : undefined
                    }
                  >
                    {detailedPlanningDetailsComponents}
                  </DetailedPlanningDetailsLayout>
                </DndProvider>
              </Layout>
            ),
            path:
              "/" +
              (
                t("detailed-planning-for-clients" as any) as string
              ).toLowerCase() +
              "/details/importversion/:importversion?/clientele/:clientele?/year/:year?/weekofyear/:weekofyear?",
          },
        ]
      : []),
    {
      element: (
        <Layout>
          <NotFound />
        </Layout>
      ),
      path: "*",
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
export default App;
