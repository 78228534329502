import { useCallback, useEffect, useState } from "react";
import Layout from "../domain/layout/Layout";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import SecurityUtil from "../helper/securityUtil";

export const PermissionGuard = ({ ...props }) => {
  const { keycloak } = useKeycloak();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { t } = useTranslation();
  const onLoad = useCallback(async () => {
    const roles = SecurityUtil.getRoles(keycloak);
    console.info("PermissionGuard #", roles);
    if (
      keycloak.authenticated &&
      props.hideForRoles &&
      props.hideForRoles.length > 0
    ) {
      let intersection = props.hideForRoles.filter((role: string) =>
        roles.includes(role),
      );
      console.info("PermissionGuard ##", roles, intersection);
      if (intersection && intersection.length > 0) {
        setIsAuthorized(false);
      } else {
        setIsAuthorized(true);
      }
    } else if (
      (!props.hideForRoles?.length && !roles.length) ||
      !keycloak.authenticated
    ) {
      console.info("PermissionGuard ###", roles, props.hideForRoles);
      setIsAuthorized(false);
    } else {
      console.info("PermissionGuard ####", roles, props.hideForRoles);
      setIsAuthorized(true);
    }
  }, [keycloak, props.hideForRoles]);

  useEffect(() => {
    onLoad();
  }, [keycloak, onLoad]);

  return (
    <>
      {isAuthorized ? (
        <div
          style={
            props.isGridItem2
              ? {
                  padding: "8px",
                  flexGrow: 0,
                  maxWidth: "16.666667%",
                  flexBasis: "16.666667%",
                }
              : props.isGridItem3
                ? {
                    padding: "8px",
                    flexGrow: 0,
                    maxWidth: "25%",
                    flexBasis: "25%",
                  }
                : {
                    display: "initial",
                    padding: "initial",
                  }
          }
        >
          {props.children}
        </div>
      ) : props.showErrorMessage ? (
        <Layout>
          <div className="data-area-div">
            <h3>{t("unauthorized-content")}</h3>
          </div>
        </Layout>
      ) : (
        <></>
      )}
    </>
  );
};
